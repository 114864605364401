@import "../../scss/variables";
@import "../../scss/mixins";
.main-features {
  .hero-header {
    margin-bottom: 35px;
    @include breakpoint-up(lg) {
      margin-bottom: 95px;
    }
  }
  .hero-header__wrapper {
    @include breakpoint-up(md) {
      padding-bottom: 55px;
    }
    border-bottom: 1px solid rgba($color-border, 0.21);
  }
  .hero-header__img {
    @include breakpoint-up(xl) {
      margin-left: -83px;
    }
  }
}
