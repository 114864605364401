@import "../../scss/variables";
@import "../../scss/mixins";
.main-footer {
  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding: 32px 0;
    @include breakpoint-up(md) {
      flex-direction: row;
    }
  }
  &__logo {
    max-width: 163px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
    @include breakpoint-up(md) {
      margin-bottom: 0;
      margin-left: 0;
    }
  }
  &__menu {
    display: flex;
    padding: 0;
    margin: 0;
    flex-direction: column;
    @include breakpoint-up(sm) {
      flex-direction: row;
    }
    &__item {
      list-style: none;
      text-align: center;
      @include breakpoint-up(sm) {
        margin-left: 40px;
        &:first-of-type {
          margin-left: 0;
        }
      }
    }
    &__link {
      color: $color-text-footer;
      transition: $transition-main;
      &:hover {
        color: $color-secondary;
        text-decoration: none;
      }
    }
  }
}
.contact-footer {
  border-top: 1px solid rgba($color-border, 0.21);
  .main-footer__content {
    &::before {
      position: absolute;
      content: '';
      background-image: url('../../svg/colored/plant.svg');
      right: 0;
      bottom: 100%;
      width: 111px;
      height: 71px;
      display: flex;
    }
  }
}
