@import "../../scss/variables";
@import "../../scss/mixins";
.section {
  max-width: 475px;
  margin: 0 auto 80px auto;
  @include breakpoint-up(md) {
    max-width: 100%;
  }
  @include breakpoint-up(lg) {
    margin-bottom: 120px;
  }
  .section-inner {
    padding: 40px 30px;
    @include breakpoint-up(lg) {
      padding: 80px;
    }
  }
  &__content {
    max-width: 475px;
    margin: 0 auto 0 auto;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include breakpoint-up(md) {
      align-items: flex-start;
      padding-bottom: 0;
      //max-width: 378px;
    }
    @include breakpoint-up(lg) {
      margin-left: auto;
      margin-right: 0;
    }
    &__icon {
      @include staticDimensions(51px, 50px);
      border-radius: 12px;
      background-color: $color-secondary;
      margin-bottom: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__title {
      text-align: center;
      @include breakpoint-up(md) {
        text-align: left;
      }
    }
    &__description {
      text-align: center;
      @include breakpoint-up(md) {
        text-align: left;
        line-height: 2.18;
      }
      p {
        margin: 0;
      }
    }
    &__list {
      margin-top: 5px;
      padding-bottom: 25px;
      padding-top: 25px;
      display: flex;
      width: 100%;
      border-top: 1px solid rgba($color-primary, 0.2);
      justify-content: center;
      @include breakpoint-up(md) {
        justify-content: flex-start;
      }
      ul {
        padding: 0;
        margin: 0;
        li {
          list-style: none;
          margin-bottom: 16px;
          line-height: 1.625;
          color: $color-primary;
          font-weight: $medium;
          display: flex;
          align-items: center;
          justify-content: center;
          @include breakpoint-up(md) {
            justify-content: flex-start;
          }
          &::before {
            content: '';
            position: relative;
            background: url('../../svg/colored/tick.svg');
            display: inline-flex;
            width: 14px;
            margin-right: 16px;
            height: 11px;
          }
        }
      }
    }
  }
  &--with-bg {
    max-width: 475px;
    margin: 0 auto 80px auto;
    @include breakpoint-up(md) {
      max-width: 100%;
    }
    @include breakpoint-up(lg) {
      margin-bottom: 120px;
    }
    .section-inner {
      border-radius: 32px;
    }
    .section__img {
      padding: 0;
      margin: 0;
    }
    .section__content {
      max-width: 475px;
      margin-right: 0;
      @include breakpoint-up(md) {
        //max-width: 378px;
      }
      @include breakpoint-up(xl) {
        margin-left: auto;
      }
    }
    &.section--reverse {
      .section__content {
        padding-left: 0 !important;
        margin-left: 0 !important;
      }
    }
  }

  &__img {
    width: 100%;
    max-width: 356px;
    margin: 0 auto;
    @include breakpoint-up(md) {
      max-width: 530px;
    }
    @include breakpoint-up(xl) {
      margin: 0;
    }
    img {
      width: 100%;
    }
  }
  &--reverse {
    .section__content {
      @include breakpoint-up(lg) {
        margin-left: 0;
      }
    }
    .section__img {
      padding-left: 0;
      margin-left: 0;
      @include breakpoint-up(md) {
        margin-left: auto;
      }
    }
  }
}
.accordion {
  border-top: 1px solid rgba($color-primary, 0.1);
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.card {
  border-bottom: 1px solid rgba($color-primary, 0.1);
  padding: 16px 0 16px 0;
  padding-left: 30px;
  position: relative;
  &-header {
    &__icon {
      width: 14px;
      height: 14px;
      position: absolute;
      left: 0;
      margin-right: 16px;
      display: inline-flex;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        height: 2px;
        background-color: $color-secondary;
        top: 50%;
        transform: translateY(-50%);
      }
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        height: 2px;
        background-color: $color-secondary;
        transition: $transition-main;
        top: 50%;
        transform: translateY(-50%);
      }
      &.closed {
        &::after {
          transform: translateY(-50%) rotate(90deg) ;
        }
      }
    }
    button {
      background-color: transparent;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      color: $color-primary;
      font-weight: $medium;
      line-height: 1.55;
      padding: 0;
      width: 100%;
      text-align: left;
    }
  }
  &-body {
    font-size: 14px;
    padding-top: 4px;
  }
}
.middle-section {
  padding: 60px 0 60px;
  margin-bottom: 60px;
  max-width: 100%;
  @include breakpoint-up(lg) {
    padding: 120px 0 120px;
    margin-bottom: 120px;
  }
  .section {
    .container {
      padding: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &.section {
    .section__content--middle {
      max-width: 100%;
      .section__content__title {
        text-align: center;
      }
      .section__content__description {
        text-align: center;
        margin-bottom: 48px;
        max-width: 100%;
      }
    }
  }
  .middle-section__icons {
    padding-bottom: 60px;
    &__single {
      width: 100%;
      max-width: 268px;
      margin: 0 auto;
      background-color: white;
      border-radius: 12px;
      margin-bottom: 30px;
      box-shadow: 0 4px 18px 0 rgba(24,27,64,0.03);
      padding: 35px;
      @include breakpoint-up(md) {
        padding: 54px 52px;
        margin-bottom: 60px;
      }
      &__icon {
        margin-bottom: 24px;
        display: flex;
        justify-content: center;
      }
      &__title {
        text-align: center;
        font-size: 20px;
        @include breakpoint-up(md) {
          font-size: 24px;
        }
        color: $color-icon-box-title;
        line-height: 1.08;
      }
    }
  }
}
.section--basic {
  .section__content {
    max-width: 509px;
    @include breakpoint-up(md) {
      padding-bottom: 0;
    }
  }
  margin-bottom: 40px;
  .section__img {
    max-width: 450px;
  }
  &.section--reverse {
    .section__img {
      @include breakpoint-up(lg) {
        margin-left: auto;
      }
    }
  }
}
.main-solutions {
  .hero-header {
    & + .section {
      .section-inner {
        padding: 0 0 80px 0;
        @include breakpoint-up(lg) {
          margin: 0 80px;
          padding: 0 0 120px 0;
        }

        border-bottom: 1px solid rgba($color-border, 0.21);
      }
    }
  }
}
