@import '../../scss/variables';
@import '../../scss/mixins';

.main-404 {
  position: relative;
  height: 100vh;
  min-height: 780px;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  margin-top: -80px;
  @include breakpoint-up(md) {
    align-items: center;
    margin-top: 0;
  }
  &::before {
    content: "";
    background-image: url('../../svg/colored/clouds.svg');
    left: 0;
    top: 5%;
    width: 300%;
    height: 100%;
    max-height: 400px;
    position: absolute;
    background-repeat: repeat-x;
    z-index: -2;
    justify-content: center;
    background-position: 40% 15%;
    display: flex;
    transform: translate3d(0, 0, 0);
    animation: moveSlideshowX 60s linear infinite;
  }
}
.not-found__heading {
  text-align: center;
  font-size: 64px;
}
.not-found__subheading {
  font-size: 32px;
  text-align: center;
}
.not-found__return {
  display: inline-flex;
  text-align: center;
  color: $color-primary;
  font-weight: $bold;
  text-decoration: underline;
  transition: $transition-main;
  margin-top: 16px;
  margin-bottom: 32px;
  font-size: 18px;
  &:hover {
    color: $color-secondary;
  }
}
