* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  //@include fluid-type($min_width, $max_width, $min_font, $max_font);
}
body {
  //font-size: $base-font-size;
  overflow-x: hidden;
  font-family: $circular;
  font-weight: $light;
  color: $color-text;
  font-size: 14px;
  line-height: 1.57;
  @include breakpoint-up(md) {
    font-size: 16px;
    line-height: 1.875;
  }
}
img {
  max-width: 100%;
}
.container {
  max-width: 1280px;
  &--compact {
    max-width: 1200px;
  }
}
.w-100 {
  width: 100%;
}
svg {
  width: 100%;
}
.accordion__elem__content,
.collapse__elem__content {
  display: none;
}

.fade {
  @include transition($transition-fade);

  &:not(.show) {
    opacity: 0;
  }
}

.collapse {
  &:not(.show) {
    display: none;
  }
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  @include transition($transition-collapse);
}


.page {
  position: absolute;
  left: 0;
  right: 0;
  transform-origin: center top;
}

.page-enter {
  opacity: 0;
  @include breakpoint-up(lg) {
    transform: scale(1.1);
  }
}

.page-enter-active {
  opacity: 1;
  @include breakpoint-up(lg) {
    transform: scale(1);
  }
  transition: opacity 900ms, transform 600ms;
}

.page-exit {
  opacity: 1;
  @include breakpoint-up(lg) {
    transform: scale(1);
  }
}

.page-exit-active {
  opacity: 0;
  @include breakpoint-up(lg) {
    transform: scale(0.9);
  }
  transition: opacity 300ms, transform 300ms;
}
