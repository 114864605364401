@import "../../scss/variables";
@import "../../scss/mixins";
.try-for-free-box {
  background-color: $color-primary;
  border-radius: 32px;
  padding: 72px 24px;
  max-width: 475px;
  margin: 0 auto;
  @include breakpoint-up(md) {
    padding: 47px 40px;
    max-width: 100%;
  }
  @include breakpoint-up(lg) {
    padding: 47px 82px;

  }
  color: white;
  &__content {

    margin: 0 auto;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include breakpoint-up(md) {
      align-items: flex-start;
      margin-bottom: 0;
      margin-left: 0;
    }
    &__title {
      color: white;
      text-align: center;
      @include breakpoint-up(md) {
        text-align: left;
      }
    }
    &__description {
      max-width: 400px;
      margin-bottom: 40px;
      text-align: center;
      opacity: .57;
      @include breakpoint-up(md) {
        text-align: left;
      }
    }
  }
}
.try-for-free-box__carousel {
  position: relative;
  overflow-y: hidden;
  max-height: 250px;
  max-width: 455px;
  @include breakpoint-up(md) {
    max-height: 275px;
  }
  @include breakpoint-up(lg) {
    max-height: 395px;
  }
  margin-left: auto;
  &::before {
    content: '';
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 124px;
    background: linear-gradient(180deg, $color-primary 0%, rgba($color-primary, 0.19) 90%, rgba($color-primary, 0) 100%);
  }
  &::after {
    content: '';
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 124px;
    background: linear-gradient(180deg, rgba($color-primary, 0) 0%, rgba($color-primary, 0.19) 10%, $color-primary 100%);
  }
}
.try-for-free-box__carousel__items {
  transform: translate3d(0, 0, 0);
  animation: moveSlideshow 10s linear infinite;
  @include breakpoint-up(lg) {
    padding-left: 50px;
  }
  &:hover {
    animation-play-state: paused;
  }
}
.try-for-free-box__carousel__items__single {
  display: flex;
  align-items: center;
  padding: 8px 0;
  @include breakpoint-up(md) {
    padding: 4px 0;
  }
  &__icon {
    @include staticDimensions(35px);
    @include breakpoint-up(md) {
      @include staticDimensions(49px);
    }
    background-color: rgba(#EAF8FD, 0.35);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6.4px;
    margin-right: 16px;
    img {
      @include staticDimensions(18px, 14px);
      @include breakpoint-up(md) {
        @include staticDimensions(20px, 16px);
      }
    }
  }
  &__label {
    font-size: 14px;
    line-height: 1.5;
    @include breakpoint-up(md) {
      font-size: 16px;
    }
  }
}
