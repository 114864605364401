@import "../../scss/variables";
@import "../../scss/mixins";
.navbar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: white;
  z-index: 100;
  border-bottom: 1px solid white;
  transition: border-bottom-color $transition-main;
  &.scrolled {
    border-bottom-color: rgba($color-border, 0.16);
  }
  &.menu-opened {
    @include breakpoint-down(lg) {
      border-bottom-color: rgba($color-border, 0.16);
    }
  }
}
.main-navigation {
  display: flex;
  align-items: center;
  padding: 17px 0;
}
.main-navigation__logo {
  margin-right: auto;
  width: 100%;
  max-width: 148px;
  @include breakpoint-up(sm) {
    max-width: 163px;
  }
}
.main-navigation__trigger {
  position: relative;
  width: 100%;
  max-width: 20px;
  height: 18px;
  display: flex;
  margin-left: 20px;
  cursor: pointer;
  span {
    background-color: $color-border;
    width: 100%;
    height: 2px;
    position: absolute;
    transition: $transition-main;
    border-radius: 50px;
    &:first-child {
      top: 0;
    }
    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
      max-width: 80%;
    }
    &:last-child {
      bottom: 0;
    }

  }
  &.active {
    span {
      &:first-child {
        top: 50%;
        transform: rotate(45deg);
      }
      &:nth-child(2) {
        transform: translateX(-100%);
        opacity: 0;
        visibility: hidden;
      }
      &:last-child {
        top: 50%;
        transform: rotate(-45deg);
      }
    }
  }
  @include breakpoint-up(lg) {
    display: none;
  }
}
.main-navigation__menu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  padding: 20px 0 20px 0;
  box-shadow: 4px 0 18px 0 rgba(24,27,64,0.03);
  display: flex;
  flex-direction: column;
  transform: translateX(-100%);
  transition: $transition-main;
  z-index: 100;
  margin-top: 56px;
  &.active {
    transform: translateX(0);
  }
  @include breakpoint-up(lg) {
    position: relative;
    max-width: 100%;
    box-shadow: none;
    padding: 0;
    flex-direction: row;
    justify-content: center;
    transform: translateX(0);
    margin-top: 0;
  }
  &__list {
    padding: 0;
    margin: auto 0;
    display: flex;
    flex-direction: column;
    @include breakpoint-up(lg) {
      flex-direction: row;
      margin: 0;
    }
    &__item {
      list-style: none;
    }
    &__link {
      padding: 10px 20px;
      color: $color-text-dark;
      transition: $transition-main;
      line-height: 1.25;
      width: 100%;
      display: flex;
      font-size: 24px;

      @include breakpoint-up(lg) {
        font-size: 16px;
        font-weight: $light;
        width: auto;
        padding: 0 24px;
      }
      &.active,
      &:hover {
        color: $color-secondary;
        text-decoration: none;
      }
      &.active {
        font-weight: $bold;
        pointer-events: none;
        @include breakpoint-up(lg) {
          font-weight: $light;
        }
      }
    }
  }
}
.main-navigation__right {
  display: flex;
  align-items: center;
  @include breakpoint-up(lg) {
    margin-left: auto;

  }
}
.main-navigation__login {
  color: $color-secondary;
  border-radius: 50px;
  line-height: 1.25;
  background-color: rgba(71,185,255,0.17);
  transition: $transition-main;
  display: none;
  font-size: 16px;
  padding: 7px 25px 9px 25px;
  margin-left: 24px;
  @include breakpoint-up(lg) {
    display: inline-flex;
  }
  &:hover {
    text-decoration: none;
    background-color: $color-secondary;
    color: white;
  }
}
.main-navigation__lang {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 101;
  &__icon {
    display: inline-flex;
    align-items: center;
    @include staticDimensions(15px);
    svg {
      fill: transparent;
      stroke: $color-border;
    }
  }
  &__chooser {
    padding: 0 4px;
    display: flex;
    align-items: center;
    position: relative;
    .current-lang {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: $color-text-dark;
    }
    &__arrow {
      @include staticDimensions(8px, 5px);
      display: inline-flex;
      align-items: center;
      margin-left: 4px;
      svg {
        fill: $color-border;
      }
    }
  }
  .lang-list {
    margin: 0;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 4px 18px 0 rgba(24,27,64,0.03);
    position: absolute;
    left: 0;
    top: 100%;
    opacity: 0;
    visibility: hidden;
    transform: translateY(100%);
    transition: $transition-main;
    &__item {
      list-style: none;
      padding: 4px;
    }
    &__link {
      color: $color-text-dark;
      text-transform: uppercase;
      &:hover {
        text-decoration: none;
        color: $color-secondary;
      }
    }
  }
  &__chooser {
    cursor: pointer;
    &:hover {
      .lang-list {
        opacity: 1;
        visibility: visible;
        transform: none;
      }
    }
  }
}
