@import '../../scss/variables';
@import '../../scss/mixins';
.contact-header {
  padding: 80px 0 40px 0;
  @include breakpoint-up(md) {
    padding-top: 150px;
  }
  &__title,
  &__description {
    @include breakpoint-up(lg) {
      padding-left: 83px;
    }
  }
}
.contact-info {
  margin-bottom: 40px;
  @include breakpoint-up(md) {
    padding-left: 83px;
    margin-bottom: 0;
  }
}
.contact-info-group {
  margin-bottom: 48px;
  &:last-child {
    margin-bottom: 0;
  }
  &__content {
    font-size: 18px;
    line-height: 1.66;
    color: $color-primary;
    p {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    a {
      color: $color-secondary;
      transition: $transition-main;
      &:hover {
        color: $color-btn-primary-hover;
      }
    }
  }
}
.contact-form {
  border: 3px solid $color-form-border;
  border-radius: 8px;
  min-height: 496px;
  max-width: 496px;
  position: relative;
  margin-bottom: 40px;
  @include breakpoint-up(sm) {
    padding: 10px;
  }
  @include breakpoint-up(md) {
    margin-bottom: 0;
  }
  @include breakpoint-up(lg) {
    margin-left: 83px;
  }
  &::before {
    content: '';
    background-image: url('../../svg/colored/form-illustration.svg');
    right: 100%;
    bottom: 0;
    width: 90px;
    height: 260px;
    display: flex;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    transform: translateX(-3px);
    @include breakpoint-down(md) {
      display: none;
    }
  }
  form {
    padding: 40px 20px;
    @include breakpoint-up(sm) {
      border: 2px solid $color-form-border-2;
      padding: 40px;
    }
    border-radius: 8px;
  }
  .btn {
    justify-content: center;
  }
}
.input-group {
  position: relative;
  margin-bottom: 16px;
  cursor: text;
  label {
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);
    transition: $transition-main;
    color: $color-placeholder;
    margin: 0;
    user-select: none;
  }
  input,
  textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid $color-form-border-2;
    border-radius: 50px;
    padding: 24px 24px 10px 24px;
    line-height: 1.25;
    width: 100%;
    &::placeholder {
      opacity: 0;
      visibility: hidden;
    }
    &:focus {
      outline: none;
      border-color: $color-secondary;
      + label {
        font-size: 80%;
        top: 16px;
      }
    }
    &.is-filled {
      + label {
        font-size: 80%;
        top: 16px;
      }
    }
  }
  textarea {
    border-radius: 8px;
    min-height: 113px;
    max-height: 250px;
    margin-bottom: 20px;
    + label {
      top: 24px;
    }
  }
  &--icon {
    label {
      left: 65px;
    }
    input {
      padding-left: 65px;
    }
  }
  &--email {
    &::before {
      content: '@';
      position: absolute;
      color: $color-placeholder;
      font-size: 22px;
      line-height: 100%;
      top: 50%;
      transform: translateY(-50%);
      left: 24px;
    }
  }
  &--password {
    &::before {
      content: '';
      position: absolute;
      background-image: url('../../svg/colored/lock.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      top: 50%;
      transform: translateY(-50%);
      left: 24px;
      width: 20px;
      height: 20px;
      display: flex;
    }
  }
}
.main-contact {
  position: relative;
  min-height: 93vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 40px;
  @include breakpoint-up(md) {
    padding-bottom: 150px;
  }
  &::before {
    content: "";
    background-image: url('../../svg/colored/clouds.svg');
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: 1340px;
    z-index: -1;
    justify-content: center;
    background-position: 40% 15%;
    display: flex;
  }
}
.form-error-message {
  padding: 15px 30px 10px 30px;
  text-align: center;
  line-height: 1.5;
  color: red;
}
