$min_width: 320px;
$max_width: 768px;
$min_font: 14px;
$max_font: 16px;

$base-font-size: 16px;

$breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px,
);

//Colors
$color-text: #74808D;
$color-text-dark: #0E1631;
$color-text-footer: #8C96A1;
$color-primary: #141B2C;
$color-secondary: #47B9FF;
$color-light-blue: #E5F7FD;
$color-light-gray: #F7F8FB;
$color-border: #949DA8;
$color-border-circle: #88929E;
$color-btn-primary-hover: #24C6FF;
$color-icon-box-title: #9DA5AF;
$color-form-border: #28255B;
$color-placeholder: #969DA5;
$color-form-border-2: rgba(#969DA5, 0.21);


//Fonts
$circular: "CircularStd", sans-serif;

//Font weights
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$black: 800;

//Transitions
$transition-main: .2s ease;
$transition-slow: .7s ease;
