@import '../../scss/variables';
@import '../../scss/mixins';

.main-terms {
  position: relative;
  overflow-x: hidden;
  &::before {
    content: "";
    background-image: url('../../svg/colored/clouds.svg');
    left: 0;
    top: 5%;
    width: 300%;
    height: 100%;
    max-height: 400px;
    position: absolute;
    background-repeat: repeat-x;
    z-index: -2;
    justify-content: center;
    background-position: 40% 15%;
    display: flex;
    transform: translate3d(0, 0, 0);
    animation: moveSlideshowX 60s linear infinite;
  }
}
.terms {
  padding: 100px 0 100px 0;
  @include breakpoint-up(lg) {
    padding: 150px 0 100px 0;
  }
  &__title {
    margin-bottom: 60px;
  }
}
