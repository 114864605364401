@import '../../scss/variables';
@import '../../scss/mixins';
.main-solutions {
  .hero-header {
    margin-bottom: 60px;
    padding-bottom: 60px;
    @include breakpoint-up(lg) {
      padding-bottom: 120px;
      padding-top: 150px;
    }
    &__content {
      margin: 0 auto 80px auto;
    }
    &__title {
      text-align: center;
    }
    &__description {
      text-align: center;
      margin: 0 auto 40px auto;
      max-width: 100%;
      p {
        margin: 0;
      }
    }
    &__img {
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: 302px;
      margin: 0 auto;
    }
  }
}
