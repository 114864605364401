@import '../../scss/variables';
@import '../../scss/mixins';
.btn-container {
  display: flex;
  width: 100%;
}
.btn {
  padding: 14px 22px;
  font-weight: $bold;
  font-size: 16px;
  line-height: 1.35;
  background-color: $color-secondary;
  border-radius: 26px;
  color: white;
  border: 2px solid $color-secondary;
  transition: $transition-main;
  display: inline-flex;
  align-items: center;
  svg {
    @include staticDimensions(16px, 10px);
    fill: white;
    margin-left: 16px;
    transition: fill $transition-main;
  }
  &:hover {
    text-decoration: none;
    background-color: transparent;
    color: $color-secondary;
    svg {
      fill: $color-secondary;
    }
  }
}
