@import '../../scss/variables';
@import '../../scss/mixins';

.main-login {
  position: relative;
  height: 100vh;
  min-height: 780px;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  margin-top: -80px;
  @include breakpoint-up(md) {
    align-items: center;
    margin-top: 0;
  }
  &::before {
    content: "";
    background-image: url('../../svg/colored/clouds.svg');
    left: 0;
    top: 5%;
    width: 300%;
    height: 100%;
    max-height: 400px;
    position: absolute;
    background-repeat: repeat-x;
    z-index: -2;
    justify-content: center;
    background-position: 40% 15%;
    display: flex;
    transform: translate3d(0, 0, 0);
    animation: moveSlideshowX 60s linear infinite;
  }
}
.login-logo {
  padding: 0 0 54px 0;
  margin: 0 auto;
  max-width: 238px;
  margin-top: 60px;
  @include breakpoint-up(md) {
    margin-top: -84px;
  }
}
.login-form {
  width: 100%;
  max-width: 534px;
  margin: 0 auto;
  border-radius: 32px;
  border: 2px solid $color-form-border-2;
  padding: 40px 20px;
  background-color: white;
  position: relative;
  @include breakpoint-up(md) {
    padding: 95px 75px;
  }
  &::after {
    content: '';
    background-image: url('../../svg/colored/shield-agent.svg');
    left: 100%;
    bottom: -77px;
    width: 216px;
    height: 350px;
    display: flex;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    transform: translateX(-7px);
    @include breakpoint-down(md) {
      display: none;
    }
  }
  &__title {
    text-align: center;
    margin-bottom: 32px;
  }
  .forgotten-password {
    color: $color-primary;
    font-weight: $bold;
    text-align: center;
    text-decoration: underline;
    transition: $transition-main;
    margin-top: 16px;
    margin-bottom: 32px;
    &:hover {
      color: $color-secondary;
    }
  }
  .btn {
    justify-content: center;
  }
}
