@import "../../scss/variables";
@import "../../scss/mixins";
@import "../../scss/keyframes";
.hero-header {
  padding-top: 110px;
  margin-bottom: 95px;
  display: flex;
  align-items: center;
  @include breakpoint-up(lg) {
    min-height: 640px;
  }
  &__title {
    margin-bottom: 16px;
  }
  &__description {
    margin-bottom: 40px;
  }
  .btn-container {
    margin-bottom: 20px;
    @include breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
}

.hero-header {
  &__content {
    max-width: 510px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 3;
    @include breakpoint-up(md) {
      text-align: left;
      margin-left: 0;
    }
    @include breakpoint-up(xl) {
      margin-left: 83px;
    }
  }
  &__description {
    max-width: 432px;
    margin: 0 auto 40px auto;
    @include breakpoint-up(md) {
      text-align: left;
      margin-left: 0;
    }
  }
  &__img {
    max-width: 320px;
    margin: 0 auto;
    @include breakpoint-up(md) {
      max-width: 100%;
    }
    @include breakpoint-up(xxl) {
      min-width: 722px;
      margin-left: -45px;
    }

  }
}
.what-we-do {
  &__title {
    text-align: center;
  }
  &__description {
    text-align: center;
    max-width: 520px;
    margin: 0 auto 80px auto;
    line-height: 1.5;
  }
}
.what-we-do__icons {
  border-bottom: 1px solid rgba($color-border, 0.21);
  margin-bottom: 40px;
  @include breakpoint-up(md) {
    padding-bottom: 40px;
    margin-bottom: 120px;
  }
}
.what-we-do__icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 35px;
  &__title {
    color: $color-text-dark;
    transition: $transition-main;
  }
  &__img {
    @include staticDimensions(90px, 81px);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    margin-bottom: 40px;
  }
  &__description {
    text-align: center;
    max-width: 296px;
    color: $color-text;
  }
  &:hover {
    text-decoration: none;
    .what-we-do__icon__title {
      color: $color-secondary;
    }
  }
}
.section--circles {
  .section__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 40px;
    align-items: center;
    @include breakpoint-up(md) {
      align-items: flex-start;
      max-width: 358px;
    }
    @include breakpoint-up(xl) {
      margin-left: 40px;
      margin-bottom: 0;
    }
  }
}
.circles-motion {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 296px;
  max-width: 296px;
  @include staticDimensions(296px);
  margin: 0 auto;
  @media screen and (min-width: 356px) {
    @include staticDimensions(308px);
  }
  @include breakpoint-up(md) {
    @include staticDimensions(423px);
  }
  @include breakpoint-up(lg) {
    @include staticDimensions(575px);
  }
}
.circles-motion__circles {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  &__single {
    border-radius: 50%;
    background: rgb(255,255,255);
    background: radial-gradient(circle, rgba(255,255,255,0.33) 0%, rgba(219,241,255,0.33) 50%, rgba(255,255,255,0.08) 100%);
    border: 1px dashed $color-border-circle;
    &:first-child {
      width: 100%;
      height: 100%;
      opacity: .45;
    }
    &:nth-child(2) {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 75%;
      height: 75%;
      opacity: .88;
    }
    &:last-child {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      height: 50%;
      opacity: 1;
    }
  }
}
.circles-motion__logos {
  &__single {
    background-color: white;
    box-shadow: 0 4px 11px 0 rgba(59,65,87,0.05);
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    //animation-name: float;
    //animation-iteration-count: infinite;
    //animation-timing-function: ease-in-out;
    //animation-duration: 4s;
    &:first-child {
      left: 10%;
      top: 0;
      @include staticDimensions(69px);
      padding: 8px;
      @include breakpoint-up(md) {
        @include staticDimensions(89px);
        padding: 12px;
      }
    }
    &:nth-child(2) {
      //animation-duration: 3s;
      @include staticDimensions(56px);
      padding: 13px;
      top: 25%;
      left: 30%;
    }
    &:nth-child(3) {
      //animation-duration: 4s;
      @include staticDimensions(64px);
      padding: 10px;
      top: 15%;
      left: auto;
      right: 20%;
    }
    &:nth-child(4) {
      //animation-duration: 5s;
      @include staticDimensions(71px);
      padding: 12px;
      top: 60%;
      left: 10%;
    }
    &:nth-child(5) {
      //animation-duration: 4s;
      @include staticDimensions(56px);
      padding: 16px;
      top: auto;
      bottom: 10%;
      left: 40%;
    }
    &:nth-child(6) {
      //animation-duration: 3s;
      @include staticDimensions(69px);
      padding: 12px;
      @include breakpoint-up(md) {
        @include staticDimensions(90px);
        padding: 20px;
      }
      top: auto;
      bottom: 30%;
      left: auto;
      right: 10%;
    }

  }
  &__main {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 30%;
  }
}
.homepage {
  .section {
    .section__content {
      max-width: 365px;
      .section__content__description,
      .section__content__list {
        max-width: 358px;
      }
    }

  }

}
