@keyframes float {
  0% {
    transform: translate(0%,0%);
  }
  25% {
    transform: translate(5%,15%);
  }
  50% {
    transform: translate(10%,5%);
  }
  75% {
    transform: translate(0%,15%);
  }
  100% {
    transform: translate(0%,0%);
  }
}
@keyframes moveSlideshow {
  100% {
    transform: translateY(-50%);
  }
}
@keyframes moveSlideshowX {
  100% {
    transform: translateX(-66.6666%);
  }
}
