.h1 {
  font-weight: $bold;
  font-size: 24px;
  line-height: 1.24;
  @include breakpoint-up(md) {
    font-size: 30px;
  }
  @include breakpoint-up(xl) {
    font-size: 50px;
  }

}
.h2 {
  font-weight: $bold;
  font-size: 22px;
  margin-bottom: 16px;
  line-height: 1.275;
  @include breakpoint-up(md) {
    font-size: 30px;
  }
  @include breakpoint-up(xl) {
    font-size: 40px;
  }
}
.h3 {
  font-weight: $black;
  font-size: 18px;
  line-height: 1.25;
  margin-bottom: 16px;
  @include breakpoint-up(md) {
    font-size: 20px;
  }
  @include breakpoint-up(xl) {
    font-size: 24px;
  }
}
.h4 {
  font-weight: $bold;
  font-size: 18px;
  margin-bottom: 16px;
  @include breakpoint-up(md) {
    font-size: 18px;
  }
  @include breakpoint-up(xl) {
    font-size: 20px;
  }
}
.h1, .h2, .h3, .h4 {
  color: $color-primary;
}
