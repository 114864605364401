@mixin breakpoint-up($breakpoint) {

  @if map-has-key($breakpoints, $breakpoint) {

    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (min-width: $breakpoint-value) {
      @content;
    }

  } @else {

    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin breakpoint-down($breakpoint) {

  @if map-has-key($breakpoints, $breakpoint) {

    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  } @else {

    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin breakpoint-between($lower, $upper) {

  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {

    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }

  } @else {

    @if (map-has-key($breakpoints, $lower) == false) {

      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    @if (map-has-key($breakpoints, $upper) == false) {

      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}
@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
    & {

      font-size: $min-font-size;

      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }

      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}
@mixin staticDimensions($width, $height : $width) {
  max-width: $width;
  min-width: $width;
  width: $width;
  min-height: $height;
  max-height: $height;
  height: $height;
}
